import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, description, image }) => {
    const { pathname } = useLocation()
    const { site: { siteMetadata } } = useStaticQuery(query)

    const { basePath, defaultDescription, defaultImage, defaultTitle, titleTemplate } = siteMetadata

    const imageUrl = `${basePath}${image || defaultImage}`
    const url = `${basePath}${pathname}`

    return (
        <Helmet htmlAttributes={{ lang: 'en' }} title={title} titleTemplate={titleTemplate} defaultTitle={defaultTitle}>
            <meta charSet="utf-8" />
            <meta name="description" content={description || defaultDescription}></meta>
            <link rel="canonical" href={url}></link>

            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={defaultTitle}></meta>
            <meta property='og:title' content={title}></meta>
            <meta property="og:url" content={url} />
            <meta property="og:image" content={imageUrl}></meta>
            <meta property='og:description' content={description || defaultDescription}></meta>

            <meta name="twitter:title" content={title || defaultTitle}></meta>
            <meta name="twitter:description" content={description || defaultDescription}></meta>
            <meta name="twitter:image" content={imageUrl}></meta>
            <meta name="twitter:card" content="summary_large_image"></meta>

            <meta name="color-scheme" content="dark light"></meta>
        </Helmet>
    )
}

export default Seo

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        basePath: url
        defaultImage: image
      }
    }
  }
`